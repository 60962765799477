import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
  return useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
}

/*******/
/* GUI */
export const initGUI = (gui, inletsHolder, handleInletChange) => {
  gui.current = new window.dat.GUI()
  // gui.current.useLocalStorage = true
  // gui.current.remember(inletsHolder.current)
  gui.current
    .add(inletsHolder.current, 'browserWidth')
    .onChange(handleInletChange.current)
    .name('browser width')
    .listen()
  gui.current.add(inletsHolder.current, 'breakpoint').onChange(handleInletChange.current).listen()
  gui.current.add(inletsHolder.current, 'speed', 0.0, 2.0).onChange(handleInletChange.current).listen()
  // gui.current
  //   .add(inletsHolder.current, 'FTUI')
  //   .onChange(handleInletChange.current)
  //   .listen()
  // gui.current
  //   .add(inletsHolder.current, 'param1', -7.5, 7.5)
  //   .onChange(handleInletChange.current)
  //   .listen()
  // gui.current
  //   .add(inletsHolder.current, 'param2', -7.5, 7.5)
  //   .onChange(handleInletChange.current)
  //   .listen()
  // gui.current
  //   .add(inletsHolder.current, 'param3', -7.5, 7.5)
  //   .onChange(handleInletChange.current)
  //   .listen()
  // gui.current
  //   .add(inletsHolder.current, 'showMain')
  //   .onChange(handleInletChange.current)
  //   .name('main')
  //   .listen()
  gui.current.add(inletsHolder.current, 'gridHelper').onChange(handleInletChange.current).name('grid helper').listen()
  gui.current.add(inletsHolder.current, 'showBorders').onChange(handleInletChange.current).name('show borders').listen()
  handleInletChange.current()
}

export const initStats = (stats, breakpoint) => {
  stats.current = new window.Stats()
  stats.current.showPanel(0) // 0: fps, 1: ms, 2: mb, 3+: custom
  stats.current.dom.style.zIndex = '9999'
  stats.current.dom.style.top = breakpoint === 'xs' ? '30px' : 'auto'
  stats.current.dom.style.left = 'auto'
  stats.current.dom.style.right = breakpoint === 'xs' ? '0' : '15px'
  stats.current.dom.style.bottom = breakpoint === 'xs' ? 'auto' : '0'
  stats.current.dom.style.position = 'absolute'
  document.body.appendChild(stats.current.dom)
}

export const resizeRendererToDisplaySize = (t3d, THREE, SCENE_SIZE) => {
  if (!t3d.current.canvas) {
    return
  }

  const width = t3d.current.canvas.clientWidth | 0
  const height = t3d.current.canvas.clientHeight | 0

  // t3d.current.camera.aspect = t3d.current.canvas.clientWidth / t3d.current.canvas.clientHeight
  const aspect = t3d.current.canvas.clientWidth / t3d.current.canvas.clientHeight
  t3d.current.camera.left = (-SCENE_SIZE / 2) * aspect
  t3d.current.camera.right = (SCENE_SIZE / 2) * aspect
  t3d.current.camera.top = SCENE_SIZE / 2
  t3d.current.camera.bottom = -SCENE_SIZE / 2
  t3d.current.camera.updateProjectionMatrix()

  t3d.current.renderer.setPixelRatio(window.devicePixelRatio)
  t3d.current.renderer.setSize(width, height, false)
}
