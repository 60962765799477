// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chapter-01-js": () => import("./../../src/pages/chapter-01.js" /* webpackChunkName: "component---src-pages-chapter-01-js" */),
  "component---src-pages-chapter-02-js": () => import("./../../src/pages/chapter-02.js" /* webpackChunkName: "component---src-pages-chapter-02-js" */),
  "component---src-pages-chapter-03-js": () => import("./../../src/pages/chapter-03.js" /* webpackChunkName: "component---src-pages-chapter-03-js" */),
  "component---src-pages-chapter-04-js": () => import("./../../src/pages/chapter-04.js" /* webpackChunkName: "component---src-pages-chapter-04-js" */),
  "component---src-pages-chapter-05-js": () => import("./../../src/pages/chapter-05.js" /* webpackChunkName: "component---src-pages-chapter-05-js" */),
  "component---src-pages-chapter-06-js": () => import("./../../src/pages/chapter-06.js" /* webpackChunkName: "component---src-pages-chapter-06-js" */),
  "component---src-pages-chapter-07-js": () => import("./../../src/pages/chapter-07.js" /* webpackChunkName: "component---src-pages-chapter-07-js" */),
  "component---src-pages-chapter-08-js": () => import("./../../src/pages/chapter-08.js" /* webpackChunkName: "component---src-pages-chapter-08-js" */),
  "component---src-pages-chapter-09-js": () => import("./../../src/pages/chapter-09.js" /* webpackChunkName: "component---src-pages-chapter-09-js" */),
  "component---src-pages-chapter-10-js": () => import("./../../src/pages/chapter-10.js" /* webpackChunkName: "component---src-pages-chapter-10-js" */),
  "component---src-pages-chapter-11-js": () => import("./../../src/pages/chapter-11.js" /* webpackChunkName: "component---src-pages-chapter-11-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

