import React, { useCallback, useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames/bind'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import styles from './Layout.module.scss'
import GridHelper from './GridHelper'
import { initGUI, initStats, resizeRendererToDisplaySize, useSiteMetadata } from './utils'

import getBreakpoint from '@utils/getBreakpoint'
import isiOS from '@utils/isiOS'
import Menu from '@components/Menu'

const cx = classnames.bind(styles)

const SCENE_SIZE = 6 // in meters
const animationTick = typeof window !== 'undefined' && new Event('animation-tick')

// Build 3D Scene
const initThreeScene = (t3d) => {
  console.log('🖼 THREE SCENE 🖼')

  t3d.current.scene = new THREE.Scene()
  // t3d.current.scene.background = new THREE.Color(0xf1f1f1)

  // t3d.current.camera = new THREE.PerspectiveCamera(20, 640 / 480, 0.1, 10000)
  // t3d.current.camera.position.set(0, 0, 0)
  // t3d.current.camera.lookAt(new THREE.Vector3(0, 0, 1))
  // t3d.current.camera.updateProjectionMatrix()

  const aspect = t3d.current.canvas.clientWidth / t3d.current.canvas.clientHeight
  t3d.current.camera = new THREE.OrthographicCamera(
    (-SCENE_SIZE / 2) * aspect,
    (SCENE_SIZE / 2) * aspect,
    SCENE_SIZE / 2,
    -SCENE_SIZE / 2,
    0.01,
    1000
  )
  t3d.current.camera.updateProjectionMatrix()

  t3d.current.renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true,
    canvas: t3d.current.canvas,
    powerPreference: 'low-power', //'high-performance' 'low-power'
  })
  t3d.current.renderer.setClearColor(0x000000, 0.0)

  t3d.current.controls = new OrbitControls(t3d.current.camera, t3d.current.renderer.domElement)

  t3d.current.camera.position.x = 0
  t3d.current.camera.position.y = 0
  t3d.current.camera.position.z = 2.5

  t3d.current.controls.rotateSpeed = 1.0
  t3d.current.controls.zoomSpeed = 1.2
  t3d.current.controls.panSpeed = 0.8

  // const geometry = new THREE.BoxGeometry( 1, 1.777, 1 );
  // const material = new THREE.MeshStandardMaterial( {color: 0x00ff00} );
  // const cube = new THREE.Mesh( geometry, material );
  // t3d.current.scene.add( cube );

  const pLight = new THREE.PointLight(0xffffff, 1, 100)
  pLight.position.set(0, 2, 2)
  // const sphereSize = 1
  // const pointLightHelper = new THREE.PointLightHelper(pLight, sphereSize)
  // t3d.current.scene.add(pointLightHelper)
  t3d.current.scene.add(pLight)
  t3d.current.scene.add(new THREE.AmbientLight(0xffffff, 0.5))
}

const Layout = ({
  children,
  mobileOpen,
  setMobileOpen,
  showBorders,
  toggleBorders,
  toggleGrid,
  showUI,
  setShowUI,
  isPlaying,
}) => {
  const siteData = useSiteMetadata()

  //GUIパラメータの準備
  const gui = useRef()

  // GUI Inlets
  const inletsHolder = useRef({
    browserWidth: typeof window !== `undefined` ? window.innerWidth : 1920,
    breakpoint: '',
    speed: 1.0,
    gridHelper: false,
    showBorders: false,
  })
  const [inlets, setInlets] = useState(null)

  // GUI Inlets Handler
  const handleInletChange = useRef()
  handleInletChange.current = () => {
    setInlets(Object.assign({}, inletsHolder.current))
    toggleGrid(inletsHolder.current.gridHelper)
    toggleBorders(inletsHolder.current.showBorders)

    // 🌎 Global Scale
    document.documentElement.style.setProperty('--speed', inletsHolder.current.speed)
  }

  // Ref Elements
  const stats = useRef(null)
  const t3d = useRef({})
  const heightRef = useRef(null)
  const widthRef = useRef(null)
  const halfPageHelperRef = useRef(null)
  const menuRef = useRef(null)
  const kanvasRef = useRef(null)
  const tanvasRef = useRef(null)

  const onResize = useCallback(() => {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)

    let adjustment = 0.75
    if (isiOS() && window.matchMedia('(orientation: landscape)').matches) {
      // adjustment = 0.25
    }

    inletsHolder.current.breakpoint = getBreakpoint()
    const targetDimension = window.innerHeight > window.innerWidth ? window.innerWidth : window.innerHeight;
    kanvasRef.current.height = targetDimension * adjustment
    kanvasRef.current.width = targetDimension * adjustment
    // kanvasRef.current.width = window.innerWidth * adjustment
    // kanvasRef.current.height = window.innerHeight
    // kanvasRef.current.width = window.innerWidth

    tanvasRef.current.height = window.innerHeight
    tanvasRef.current.width = window.innerWidth
    resizeRendererToDisplaySize(t3d, THREE, SCENE_SIZE)
  }, [kanvasRef])

  const handleTick = () => {
    if (t3d.current.renderer) {
      t3d.current.renderer.render(t3d.current.scene, t3d.current.camera)
    }
  }

  const mouseTimeout = useRef(null)
  const handleMouseMove = () => {
    if (!isPlaying && showUI) {
      return
    }

    if (!isPlaying && !showUI) {
      setShowUI(true)
      return
    }

    if (isPlaying && !showUI) {
      setShowUI(true)
      if (mouseTimeout.current) {
        window.clearTimeout(mouseTimeout.current)
      }
      mouseTimeout.current = setTimeout(() => {
        setShowUI(false)
      }, 2400) // set to 0 for recording
    }
  }

  useEffect(() => {
    console.log('🌈 layout mounted')

    // On first load, mobile browsers have different initial viewport heights that change after scrolling
    // capture that difference here as a CSS variable
    document.documentElement.style.setProperty(
      '--vhThreshold',
      `${heightRef.current.offsetHeight - window.innerHeight}px`
    )
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
    document.documentElement.style.setProperty('--speed', `1.0`)

    inletsHolder.current.breakpoint = getBreakpoint()

    window.addEventListener('animation-tick', handleTick, false)

    /** GUI **/
    // initGUI(gui, inletsHolder, handleInletChange)

    /* Stats */
    // initStats(stats, getBreakpoint())

    /* THREE */
    if (kanvasRef.current) {
      t3d.current.canvas = tanvasRef.current
      initThreeScene(t3d)
      // resizeRendererToDisplaySize(t3d, THREE, SCENE_SIZE)
    }

    window.addEventListener('resize', onResize)
    onResize()

    // methods for render loop
    let frameId
    const play = () => {
      // stats && stats.current && stats.current.begin()

      window.dispatchEvent(animationTick)

      // stats && stats.current && stats.current.end()
      frameId = requestAnimationFrame(play)
    }
    play()

    /** CleanUp **/
    return () => {
      console.log('🧹tidying up 🧹')
      gui.current && gui.current.destroy()
      window.removeEventListener('resize', onResize)
      window.removeEventListener('animation-tick', handleTick)
    }
  }, [])

  return (
    <main
      className={cx('main', {
        getBreakpoint,
        'mobile-open': mobileOpen,
      })}
      onMouseMove={handleMouseMove}
    >
      {/*dummy section containers for imperative width/height measurements*/}
      <div className={`section-container`}>
        <div className={`section`}>
          <div className={`row`}>
            <div className={`col`}>
              <div ref={widthRef} style={{ visibility: 'none' }} />
            </div>
          </div>
        </div>
      </div>
      <div ref={heightRef} style={{ position: 'absolute', top: 0, left: 0, height: '100vh' }} />

      <GridHelper />
      <div
        className={cx('overlay')}
        onTouchEnd={() => (mobileOpen ? setMobileOpen(false) : null)}
        onClick={() => (mobileOpen ? setMobileOpen(false) : null)}
      />
      {showBorders && <div ref={halfPageHelperRef} className={cx('halfPageHelper')} />}
      <Menu menuRef={menuRef} siteTitle={siteData.site.siteMetadata.title} className={cx('menu-container')} />
      {/*<audio className={cx('audio')} ref={audioRef} controls />*/}
      <canvas className={cx('kanvas')} ref={kanvasRef} />
      <canvas className={cx('tanvas')} ref={tanvasRef} />
      {React.cloneElement(children, {
        kanvasRef: kanvasRef,
        t3d: t3d,
        mouseTimeout: mouseTimeout,
      })}
    </main>
  )
}

const mapStateToProps = ({ mobileOpen, showBorders, showUI, isPlaying }) => {
  return { mobileOpen, showBorders, showUI, isPlaying }
}
const mapDispatchToProps = (dispatch) => {
  return {
    toggleBorders: (target) => dispatch({ type: `TOGGLESHOWBORDERS`, payload: target }),
    toggleGrid: (target) => dispatch({ type: `TOGGLESHOWGRID`, payload: target }),
    setMobileOpen: (target) => dispatch({ type: `SETMOBILEOPEN`, payload: target }),
    setShowUI: (target) => dispatch({ type: `SETSHOWUI`, payload: target }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
