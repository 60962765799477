import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import { connect } from 'react-redux'
import querystring from 'query-string'

import styles from './Menu.module.scss'
import Link from '@components/Link'
import Text from '@components/Text'
import isiOS from '@utils/isiOS'

const cx = classnames.bind(styles)

const Menu = ({ className, showBorders, menuRef, pageTransitioningIn, pageTransitionURLTarget, showUI, isHover }) => {
  const [pageLocation, setPageLocation] = useState(typeof location !== `undefined` ? location.pathname : null)

  const handleTick = () => {
    if (location.pathname !== pageLocation) {
      setPageLocation(location.pathname)
    }
  }

  useEffect(() => {
    window.addEventListener('animation-tick', handleTick, false)
    return () => window.removeEventListener('animation-tick', handleTick)
  }, [])

  useEffect(() => {
    if (!pageTransitionURLTarget || pageTransitioningIn) {
      return
    }
    if (pageTransitionURLTarget !== pageLocation) {
      setPageLocation(pageTransitionURLTarget)
    }
  }, [pageLocation, pageTransitioningIn, pageTransitionURLTarget, setPageLocation])

  return (
    <div
      ref={menuRef}
      // style={{ display: 'none'}}
      className={`section-container ${cx('menu-container', className, {
        'mobile-open': true,
        visible: showUI || (isHover && !isiOS()),
      })}`}
    >
      <div className={`col ${cx('menu')}`}>
        <ul className={cx('non-mobile')}>
          <li>
            <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
              <Link to="/chapter-01" className={`${cx('menu-link')}`}>
                Snake Heist
              </Link>
            </Text>
          </li>

          {pageLocation?.indexOf('chapter') > -1 && (
            <>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-01" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('01') > -1 && '✵'} Chapter 01
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-02" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('02') > -1 && '✵'} Chapter 02
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-03" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('03') > -1 && '✵'} Chapter 03
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-04" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('04') > -1 && '✵'} Chapter 04
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-05" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('05') > -1 && '✵'} Chapter 05
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-06" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('06') > -1 && '✵'} Chapter 06
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-07" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('07') > -1 && '✵'} Chapter 07
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-08" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('08') > -1 && '✵'} Chapter 08
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-09" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('09') > -1 && '✵'} Chapter 09
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-10" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('10') > -1 && '✵'} Chapter 10
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/chapter-11" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('11') > -1 && '✵'} Chapter 11
                  </Link>
                </Text>
              </li>
              <li>
                <Text tag="h4" type="h4" className={`${cx('menu-link-holder')}`}>
                  <Link to="/about" className={`${cx('menu-link')}`}>
                    {pageLocation?.indexOf('about') > -1 && '✵'} About
                  </Link>
                </Text>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  )
}

Menu.propTypes = {
  menuRef: PropTypes.object,
  className: PropTypes.string,
  showBorders: PropTypes.bool,
  pageTransitioningIn: PropTypes.bool,
  pageTransitionURLTarget: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

const mapStateToProps = ({ showBorders, pageTransitioningIn, pageTransitionURLTarget, showUI, isHover }) => {
  return { showBorders, pageTransitioningIn, pageTransitionURLTarget, showUI, isHover }
}

export default connect(mapStateToProps)(Menu)
